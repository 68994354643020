import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import helper from "../utils/helper";

/**
 * Auth Pages
 */
const LoginPage = lazy(() => import("../pages/Auth/LoginPage"));
const RegisterPage = lazy(() => import("../pages/Auth/RegisterPage"));
const ForgotPage = lazy(() => import("../pages/Auth/ForgotPage"));
const ResetPage = lazy(() => import("../pages/Auth/ResetPage"));

const DashboardPage = lazy(() => import("../pages/DashboardPage"));
const DocumentationPage = lazy(() => import("../pages/DocumentationPage"));
const TopupPage = lazy(() => import("../pages/TopupPage"));
const StatusPage = lazy(() => import("../pages/StatusPage"));
const MasterPage = lazy(() => import("../pages/MasterPage"));

const BlankNotAccess = lazy(() => import("../components/Blank/BlankNotAccess"));

const PageLoader = () => (
  <div id="loader">
    <div className="loadingio-spinner-rolling-3kjtqyynjid">
      <div className="ldio-ucwibazh2i9">
        <div></div>
      </div>
    </div>
  </div>
);

const PublicRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ title, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function Router(props) {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="/no-access" render={() => <BlankNotAccess />} />
        <PublicRoute path="/login">
          <LoginPage />
        </PublicRoute>
        <PublicRoute path="/register">
          <RegisterPage />
        </PublicRoute>
        <PublicRoute path="/forgot">
          <ForgotPage />
        </PublicRoute>
        <Route
          exact
          path="/reset/:token?"
          render={(props) =>
            helper.isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            ) : props.match.params.token ? (
              <ResetPage token={props.match.params.token} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />

        <PrivateRoute path="/dashboard">
          <DashboardPage />
        </PrivateRoute>

        <PrivateRoute path="/documentation">
          <DocumentationPage />
        </PrivateRoute>
        <PrivateRoute path="/topup">
          <TopupPage />
        </PrivateRoute>
        <PrivateRoute path="/status">
          <StatusPage />
        </PrivateRoute>

        <PrivateRoute path={"*"}>
          {helper.isAuthenticated() ? <MasterPage /> : <BlankNotAccess />}
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}
export default withRouter(Router);
