import openSocket from "socket.io-client";
import helper from "./helper";

let socket = helper.isAuthenticated()
  ? openSocket(process.env.REACT_APP_SOCKET_URL, {
      query: "id=" + helper.isAuthenticated()?.user?.user_id?.toString(),
      transports: ["websocket"],
    })
  : null;
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  loading(cb) {
    if (!socket) return;
    socket.on("loading", (data) => cb(data));
  },
  message(cb) {
    if (!socket) return;
    socket.on("message", (data) => cb(data));
  },
  registerTelegran(data) {
    if (!socket) return;
    socket.emit("registerTelegram", data);
  },
  stopTelegram(data) {
    if (!socket) return;
    socket.emit("stopTelegram", data);
  },
  registerWhatsapp(data) {
    if (!socket) return;
    socket.emit("registerWhatsapp", data);
  },
  stopWhatsapp(data) {
    if (!socket) return;
    socket.emit("stopWhatsapp", data);
  },
  registerInstagram(data) {
    if (!socket) return;
    socket.emit("registerInstagram", data);
  },
  stopInstagram(data) {
    if (!socket) return;
    socket.emit("stopInstagram", data);
  },
  qrwhatsapp(cb) {
    if (!socket) return;
    socket.on("qr_whatsapp", (data) => cb(data));
  },
  readywhatsapp(cb) {
    if (!socket) return;
    socket.on("ready_whatsapp", (data) => cb(data));
  },
  triggerChannel(cb) {
    if (!socket) return;
    socket.on("trigger_channel", (data) => cb(data));
  },
};
