export const generalLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case "LOADING_SET":
      return action.loading;
    default:
      return state;
  }
};

export const generalAlertReducer = (state = false, action) => {
  switch (action.type) {
    case "ALERT_SET":
      return action.alert;
    default:
      return state;
  }
};

export const qrReduer = (state = null, action) => {
  switch (action.type) {
    case "QR_GET":
      return state;
    case "QR_SET":
      return action.qr;
    default:
      return state;
  }
};
