import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (errObj, ...args) => {
  if (args.includes("eventKey")) {
    return;
  }

  if (args.includes("warnKey")) {
    return;
  }
  consoleError(errObj, ...args);
};
const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
