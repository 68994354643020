import { put, call, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import * as actions from "../actions";
import socket from "../../utils/socket";

// eslint-disable-next-line
function* loadingEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.generalSetLoading(data));
    };
    socket.loading(socketEvent);
    return () => {};
  });
}

export function* loadingEventChannelWatcher() {
  try {
    const loading = yield call(loadingEventChannel);
    while (true) {
      const action = yield take(loading);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line
function* alertEvent() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.generalSetAlert(data));
    };
    socket.message(socketEvent);
    return () => {};
  });
}

export function* alertEventWatcher() {
  try {
    const message = yield call(alertEvent);
    while (true) {
      const action = yield take(message);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line
function* qrEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.setQrWhatsapp(data));
    };
    socket.qrwhatsapp(socketEvent);
    return () => {};
  });
}

export function* qrEventChannelWatcher() {
  try {
    const qr = yield call(qrEventChannel);
    while (true) {
      const action = yield take(qr);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line
function* readyEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.setQrWhatsapp(data));
    };
    socket.readywhatsapp(socketEvent);
    return () => {};
  });
}

export function* readyEventChannelWatcher() {
  try {
    const ready = yield call(readyEventChannel);
    while (true) {
      const action = yield take(ready);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line
function* triggerChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      window.location.href = "/" + data?.channel;
    };
    socket.triggerChannel(socketEvent);
    return () => {};
  });
}

export function* triggerChannelWatcher() {
  try {
    const trigger = yield call(triggerChannel);
    while (true) {
      const action = yield take(trigger);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}
