import { combineReducers } from "redux";
import * as interaction from "./interaction";

const appReducer = combineReducers({
  loading: interaction.generalLoadingReducer,
  qr: interaction.qrReduer,
  alert: interaction.generalAlertReducer,
});

export default appReducer;
