import { all, fork } from "redux-saga/effects";
import * as interaction from "./interaction";
export function* watchUsers() {
  yield all([
    fork(interaction.loadingEventChannelWatcher),
    fork(interaction.qrEventChannelWatcher),
    fork(interaction.readyEventChannelWatcher),
    fork(interaction.alertEventWatcher),
    fork(interaction.triggerChannelWatcher),
  ]);
}
