import axios from "axios";
const http = axios.create();
http.defaults.baseURL = process.env.REACT_APP_API_URL;
const storage = JSON.parse(localStorage.getItem("db"));
if (storage) {
  const { access_token } = storage;
  http.defaults.headers.common.Authorization = `Bearer ${access_token}`;
}

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        localStorage.removeItem("db");
        delete http.defaults.headers.common.Authorization;
        window.location.reload(true);
      }
    }
    return Promise.reject(error);
  }
);

export default http;
