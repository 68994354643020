export const generalSetLoading = (loading) => ({
  type: "LOADING_SET",
  loading,
});

export const generalSetAlert = (alert) => ({
  type: "ALERT_SET",
  alert,
});

export const setQrWhatsapp = (qr) => ({
  type: "QR_SET",
  qr,
});
